.nav-link {
    font-family: 'Patrick Hand SC';
    font-style: normal;
    font-weight: 400;
    font-size: 1.20rem;
    line-height: 99.02%;
    color: #000000;
}

.nav-link:hover {
    color: #9586DE;
}

.sidebar {
    background-color: var(--pos-secondary-color);
    border-right: 1px solid #cac5c5;
    height: 100vh;
    overflow: auto;
    text-align: center;
    width: 11rem;
    /* position: absolute; */
    left: 0;
    top: 0;
    padding: 0 0.9rem;
}

.body_container {
    /* margin-left: 6rem; */
    font-size: 18px;
    width: 100%;
    padding: 0.75rem;
    /* overflow-y: scroll;
    height: 100vh; */
}

.sidebar_button {
    border-radius: 12px;
    box-sizing: content-box;
    color: var(--pos-font-color);
    display: grid;
    padding: 8% 0;
    text-decoration: none;
    /* margin: 0.99rem; */
    margin-bottom: 0.99rem;
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 0.5rem 0.75rem;
    width: 6rem;
    /* background-image: linear-gradient(#17cba082 0, #0098b894 100%); */
    box-shadow: 3px 4px 3px 0 rgba(0, 0, 0, .15);
}

.sidebar_button svg{
    width: 1.3rem;
    margin-right: 0.25rem;
}

.sidebar_button.active, .sidebar_button:hover {
    background-image: linear-gradient(#17cba082 0, #0098b894 100%);
    box-shadow: 3px 4px 3px 0 rgba(0, 0, 0, .15);
    color: black;
}

.site-logo {
    /* background-image: linear-gradient(#09b3d4 0, #77e2f8 100%); */
    width: 100%;
}
.logoImg{
    width: 100%;
    height: 100%;
}
.sideFontSize{
    font-size: 12px;
}