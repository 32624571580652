.order_card {
    background-color: white;
    margin: 1rem 1rem 0rem 1rem;
    border: 2px solid rgb(202, 201, 201);
    border-radius: 0.7rem;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.order_card:hover {
    border: 2px solid #003342;
    transition: all 0.2s ease-in-out;
}

.order_number {
    width: 7rem;
    padding: 0.5rem 0.5rem;
    border-radius: 10px;
    margin-bottom: 1rem;
    margin-top: 1rem;
    border: 1px solid #003342;
    /* background: linear-gradient(90deg, #000000, #2e7185); */
    font-weight: 600;
    color: rgb(0, 0, 0);
}

.order_card.active {
    border: 2px solid var(--primary-color);
    color: black;
}

.date-time-box {
    background: linear-gradient(90deg, #000000, #2e7185);
    color: rgb(255, 255, 255);
    cursor: pointer;
    padding: 8px 6px;
    border: 1px solid #ccc;
    width: 100%;
    border-radius: 4px;
    font-size: 0.8rem;
    text-align: center;
    font-weight: 800;
}


.multipleAtt {
    overflow: hidden !important;
    width: 30vw;
    align-items: center;
    height: 10rem;
    position: relative;
    left: 0%!important;
    transition: all 0.3s ease-in-out;
    /* background-color: red; */
}

/* .multipleAttExp {
    transition: all 0.3s ease-in-out;
} */

.arrow-p-up{
    position: absolute;
    bottom: -34px!important;
}
.arrow-p-up .up{
    font-size: 1.5rem;
}