@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap");

:root {
  --header-height: 2.7rem;
  --nav-width: 68px;
  --first-color: #4723d9;
  --first-color-light: #afa5d9;
  --white-color: #f7f6fb;
  --body-font: "Nunito", sans-serif;
  --normal-font-size: 1rem;
  --z-fixed: 100;
  --primary-color: #003342;
  --secondery-color: #17CBA0;
  --fourth-color: #832060;
}

*,
::before,
::after {
  box-sizing: border-box;
}

body {
  position: relative;
  margin: var(--header-height) 0 0 0;
  padding: 0 1rem;
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  transition: 0.5s;
}

.sidebar_logo {
  width: 1.5rem !important;
  height: 1.5rem !important;
  border: 1px solid white;
  border-radius: 30px;
  overflow: hidden;
  transition: all 0.3 ease-in-out;
}

.sidebar_logoimg {
  width: 100%;
  height: 100%;
}



a {
  text-decoration: none;
}

.header {
  width: 100%;
  /* height: var(--header-height); */
  height: 2.5rem !important;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  background-color: var(--white-color);
  z-index: var(--z-fixed);
  transition: 0.5s;
}


.header_toggle {
  color: #003342;
  font-size: 1.5rem;
  cursor: pointer;
}

.header_img {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
}

.header_img img {
  width: 40px;
}

.l-navbar {
  position: fixed;
  top: 0;
  left: -30%;
  width: var(--nav-width);
  height: 100vh;
  /* background-color: #000000; */
  background: rgb(79,0,63);
  /* background: linear-gradient(90deg, rgba(79,0,63,1) 0%, rgba(131,32,96,1) 100%); */
  background: linear-gradient(90deg,#000000,#003342);
  padding: 0.5rem 1rem 0 0;
  transition: 0.5s;
  z-index: var(--z-fixed);
}

.nav {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.nav_logo,
.nav_link {
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  column-gap: 1rem;
  padding: 0.5rem 0 0.5rem 1.5rem;
}

.nav_logo {
  margin-bottom: 2rem;
}

.nav_logo-icon {
  font-size: 1.25rem;
  color: var(--white-color);
}

.nav_logo-name {
  color: var(--white-color);
  font-weight: 700;
}

.nav_link {
  position: relative;
  color: var(--first-color-light);
  margin-bottom: 1.5rem;
  transition: 0.3s;
}

.nav_link:hover {
  color: var(--white-color);
}

.nav_icon {
  font-size: 1.25rem;
}

.l-navbar.show {
  left: 0;
}

.body-pd {
  padding-left: calc(var(--nav-width) + 1rem);
}

.active {
  color: var(--white-color);
}

.active::before {
  content: "";
  position: absolute;
  left: 0;
  width: 2px;
  height: 32px;
  background-color: var(--white-color);
}

.height-100 {
  height: 100vh;
}

@media screen and (min-width: 768px) {
  body {
    /* margin: calc(var(--header-height) + 1rem) 0 0 0; */
    padding-left: calc(var(--nav-width) + 0.25rem);
  }

  .header {
    height: calc(var(--header-height) + 1rem);
    padding: 0 2rem 0 calc(var(--nav-width) + 1rem);
  }

  .header_img {
    width: 40px;
    height: 40px;
  }

  .header_img img {
    width: 45px;
  }

  .l-navbar {
    left: 0;
    padding: 1rem 1rem 0 0;
  }

  .l-navbar.show {
    width: calc(var(--nav-width) + 156px);
  }

  .body-pd {
    /* padding-left: calc(var(--nav-width) + 188px); */
    padding-left: calc(var(--nav-width) + 90px);
  }

  .header.body-pd {
    padding-left: calc(var(--nav-width) + 170px);
  }
}

.main_pos_content {
  transition: 0.5s;
}