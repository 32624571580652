/* Importing fonts from Google */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

/* Reseting */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

/* body {
    background: #ecf0f3;
} */

.wrapper {
    max-width: 350px;
    min-height: 500px;
    margin: 80px auto;
    overflow: hidden;
    /* padding: 40px 30px 30px 30px; */
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.logo {
    width: 100%;
    height: 16rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: linear-gradient(90deg, #727171, #2e7185);
}

.loginlogo {
    width: 80% !important;
    margin: auto !important;
    height: 100% !important;
}

.loginImgMain {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 16rem;
}

.pointOfSale {
    bottom: 2rem;
}



.wrapper .name {
    font-weight: 600;
    font-size: 1.4rem;
    letter-spacing: 1.3px;
    padding-left: 10px;
    color: #555;
}

.wrapper .form-field input {
    width: 15rem;
    display: block;
    border: 1px solid #cecdcd;
    background: white;
    font-size: 16px;
    border-radius: 8px;
    padding: 2px 15px 2px 10px;
}

.pointOfSaleLogo {
    /* font-size: 12px!important; */
    top: 20px;
    left: 0;
    width: 5rem !important;
    padding: 0 5px 0 5px;
    height: 1.5rem;
    display: flex;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    justify-content: center;
    align-items: center;
    background: linear-gradient(90deg, #a09d9d, #4fc2e6);
}

.pointOfSaleLogo img {
    width: 100% !important;
    height: 100% !important;
}

.wrapper .form-field input:focus {
    outline: var(--primary-color);
}


.inputDivMain {
    width: 100%;
    /* height: 15rem; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.inputDivMain form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 1440px) {
    .inputDivMain {
        height: 14rem!important;
    }
}
@media only screen and (min-width: 1920px) {
    .inputDivMain {
        height: 19rem!important;
    }
}
/* @media only screen and (max-width: 2560px) {
    .inputDivMain {
        height: 19rem;
    }
} */

.wrapper .form-field {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* padding-left: 10px;
    margin-bottom: 20px;
    border-radius: 20px; */
    /* box-shadow: inset 8px 8px 8px #cbced1, inset -8px -8px 8px #fff; */
}

.wrapper .form-field .fas {
    color: #555;
}

.wrapper .btn {
    /* box-shadow: none; */
    width: 80%;
    padding: 4px;
    background-color: #03A9F4;
    color: #fff;
    border-radius: 25px;
    /* box-shadow: 3px 3px 3px #b1b1b1,
        -3px -3px 3px #fff; */
    /* letter-spacing: 1.3px; */
    background: linear-gradient(90deg, #2e7185, #727171);
}

.loginSubmitBtn {
    margin: auto;
    transition: all 0.3s ease-in-out;
}

.loginSubmitBtn:hover {
    box-shadow: 0px 0px 7px 0.5px rgba(226, 186, 255, 0.75) inset;
    -webkit-box-shadow: 0px 0px 7px 0.5px rgba(226, 186, 255, 0.75) inset;
    -moz-box-shadow: 0px 0px 7px 0.5px rgba(226, 186, 255, 0.75) inset;
    transition: all 0.3s ease-in-out;
}

/* .wrapper .btn:hover {
    background-color: #039BE5;
} */

.wrapper a {
    text-decoration: none;
    font-size: 0.8rem;
    color: #03A9F4;
}

.wrapper a:hover {
    color: #039BE5;
}

@media(max-width: 380px) {
    .wrapper {
        margin: 30px 20px;
        padding: 40px 15px 15px 15px;
    }
}